import React, { useState, useRef, useEffect } from 'react'
import {
    Row,
    Col,

} from "reactstrap";
import PreviewDetails from './PreviewDetails';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import { db } from '../firsebase.config';
import { useHistory } from "react-router"
import { useParams } from "react-router"

const EnterDetails = () => {
    const { id } = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const a = new Date();
    const timestamp = a.getTime();

    const [inputField, setInputField] = useState({
        Name: "",
        Email: "",
        Address: "",
        Price: "",
        Deposite: "",
        Due_Deligence_Period: "",
        Finicial_Feasibility_Period: "",
        Closing: "",
        Commission: "",
        SenderName: '',
        Subject: "",
        date: timestamp,
        PDF: [],
        CalculatedAmount: ""
    })

    const InputHandler = (e) => {
        debugger
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    const form = useRef();
    useEffect(() => {
        async function GetUserById() {
            try {
                const docRef = doc(db, "user", id);
                const docSnap = await getDoc(docRef);
                setInputField(docSnap.data());
                emailjs.sendForm('service_nz3z4hj', 'template_b0o940c', form.current, 'FEj-3nw-YLjUpCu4w')

            } catch (e) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong!',
                })
            }
        }
        GetUserById()
    }, [])


    const [images, setImages] = useState([]);

    const UploadDocument = async (e) => {
        try {
            debugger
            for (let i = 0; i < e.target.files.length; i++) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[i]);
                reader.onload = (readerEvent) => {
                    images.push(readerEvent.target?.result.replace("data:", "").replace(/^.+,/, ""));
                };
                inputField.PDF = images;
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                text: 'Their is an error Uploading This file!',
            })
        }
    };

    const sendEmail = async (e) => {
        var a = ((inputField.Deposite / 100) * inputField.Price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        inputField.CalculatedAmount = a;
        var price = inputField.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        inputField.Price = price;

        const docRef = doc(db, "user", id);

        try {
            setLoading(true)
            debugger
            emailjs.send('service_s6sua76', 'template_54q66ji', inputField, "li2AEH_4rMP-BmR-3")
            updateDoc(docRef, {
                Name: inputField.Name,
                Email: inputField.Email,
                Address: inputField.Address,
                Price: inputField.Price,
                Deposite: inputField.Deposite,
                Due_Deligence_Period: inputField.Due_Deligence_Period,
                Finicial_Feasibility_Period: inputField.Finicial_Feasibility_Period,
                Closing: inputField.Closing,
                Commission: inputField.Closing,
                SenderName: inputField.SenderName,
                Subject: inputField.Subject,
                date: timestamp,
                PDF: [],
                CalculatedAmount: inputField.CalculatedAmount
            })
                .then(docRef => {
                    Swal.fire(
                        'Email Sended!',
                        "Email Sucessfully Send",
                        'success'
                    )
                    setLoading(false)
                    history.push("/userdata")
                })


                .catch(error => {
                    console.log(error);
                })
        } catch (e) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong!',
            })
        }

    };
    const logoutBtn = () => {
        history.push("/userForm")
    }
    return (
        <>
            <Row>
                <Col md="5">
                    <PreviewDetails inputField={inputField} />
                </Col>
                <Col md="7">

                    <div className='m-4'>
                        <Row>
                            <Col md="3">
                                <button className='mt-2 logoutBtn' onClick={logoutBtn}>Home</button>
                            </Col>
                            <Col md="6">
                                <h2 className='form-details mt-3'> Update LOI Details Here</h2>
                            </Col>

                        </Row>
                        <form ref={form} onSubmit={sendEmail}>
                            <Row className="mt-3">
                                <Col md='6' className="mt-2">
                                    <label>Listing agent name : </label>
                                    <input onChange={InputHandler} value={inputField.Name} className='form-control' type="text" required placeholder='Name' name='Name' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Email:</label>
                                    <input onChange={InputHandler} value={inputField.Email} className='form-control' type="text" required placeholder='Email' name='Email' />
                                </Col>

                                <Col md='6' className="mt-2">
                                    <label>Listing Address : </label>
                                    <input onChange={InputHandler} value={inputField.Address} className='form-control' type="text" required placeholder='Address' name='Address' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Purchase Price: </label>
                                    <input onChange={InputHandler} value={inputField.Price} className='form-control' type="number" required placeholder="Price" name='Price' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Earnest Money Deposit :<span style={{ color: "#a19a9a" }}> (%)</span> </label>
                                    <input onChange={InputHandler} value={inputField.Deposite} className='form-control' type="number" required placeholder="Deposite" name='Deposite' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Due Deligence Period : <span style={{ color: "#a19a9a" }}>(days)</span></label>
                                    <input onChange={InputHandler} value={inputField.Due_Deligence_Period} className='form-control' type="number" required placeholder="Due Deligence Period" name='Due_Deligence_Period' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Financial Contingency Period : <span style={{ color: "#a19a9a" }}>(days)</span></label>
                                    <input onChange={InputHandler} value={inputField.Finicial_Feasibility_Period} className='form-control' type="number" required placeholder="Finacial Feasibility Period" name='Finicial_Feasibility_Period' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Closing : <span style={{ color: "#a19a9a" }}>(days)</span></label>
                                    <input onChange={InputHandler} value={inputField.Closing} className='form-control' type="number" required placeholder="Closing" name='Closing' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Buyer side commission : <span style={{ color: "#a19a9a" }}>(%)</span></label>
                                    <input onChange={InputHandler} value={inputField.Commission} className='form-control' type="number" required placeholder="Commission" name='Commission' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Sender Name:</label>
                                    <input onChange={InputHandler} value={inputField.SenderName} className='form-control' type="text" required placeholder="Sender Name" name='SenderName' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Subject: </label>
                                    <input onChange={InputHandler} value={inputField.Subject} className='form-control' type="text" required placeholder="Subject" name='Subject' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Upoad File: <span style={{ color: "#a19a9a" }}>(.pdf)</span></label>
                                    <input onChange={UploadDocument} className='form-control' accept="application/pdf" type="file" multiple />
                                </Col>
                                <input onChange={InputHandler} value={inputField.CalculatedAmount} className='form-control' type="text" style={{ display: "none" }} placeholder="CalculatedAmount" name='CalculatedAmount' />

                            </Row>
                            <div className='form-details'>
                                {loading == true ? <button className='mt-5 sendBtn'
                                    type="submit"
                                    disabled
                                    onClick={sendEmail}
                                >Updating...</button> : <button className='mt-5 sendBtn'
                                    type="submit"
                                    onClick={sendEmail}
                                >Update</button>}

                            </div>
                        </form>
                    </div>
                </Col>
            </Row>


        </>
    )
}

export default EnterDetails