

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDWWha9m2SpzR_XENihdUi_tMyAESGs8Es",
  authDomain: "real-state-d21f7.firebaseapp.com",
  databaseURL: "https://real-state-d21f7-default-rtdb.firebaseio.com",
  projectId: "real-state-d21f7",
  storageBucket: "real-state-d21f7.appspot.com",
  messagingSenderId: "88578479435",
  appId: "1:88578479435:web:28399f9c7d060a1e5444ce",
  measurementId: "G-FY51KPM8QV"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)



