import FormData from "./form/EnterDetails";
import "./App.css"
import { Route, Switch } from "react-router-dom";
import LoginPage from "./form/loginForm/UserLoginPage";
import UserDataTable from "./admin/UserDataTable";
import { useEffect } from "react";
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import UpdateData from "./form/UpdateDetails";
function App() {
  let history = useHistory()

  useEffect(() => {
    let localStorageObject = localStorage.getItem("real_state_admin");
    console.log(localStorageObject)
    if (window.location.pathname == "/" || localStorageObject == null) {
      history.push("/login")
    }
  }, [])
  return (
    <>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route path="/userForm" component={FormData} />
        <Route path="/userdata" component={UserDataTable} />
        <Route path="/updateDetails/:id" component={UpdateData} />
      </Switch>
    </>
  );
}

export default App;
