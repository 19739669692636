
import React, { useState, useEffect } from 'react'
import "react-table-v6/react-table.css";
import ReactTable from "react-table-v6";
import { db } from '../firsebase.config';
import { collection, doc, getDocs, deleteDoc } from "firebase/firestore";
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useHistory } from "react-router"
import { Link } from "react-router-dom";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import Swal from 'sweetalert2';
import moment from 'moment';
const UserDataTable = () => {
    const [user, setuser] = useState([]);
    const [loading, setLoading] = useState(false)
    let history = useHistory()

    const fetchPost = async () => {

        const startDate = new Date();
        const date = startDate.setDate(startDate.getDate() - 90);
        var arr = [];
        var i = 1;
        await getDocs(collection(db, "user"))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                newData.forEach((item) => {
                    debugger
                    if (item.date >= date) {
                        arr.push({
                            no: i++,
                            id: item.id,
                            Name: item.Name,
                            Email: item.Email,
                            Address: item.Address,
                            Price: item.Price,
                            Due_Deligence_Period: item.Due_Deligence_Period,
                            Finicial_Feasibility_Period: item.Finicial_Feasibility_Period,
                            Closing: item.Closing,
                            Commission: item.Commission,
                            Deposite: item.Deposite,
                            SenderName: item.SenderName
                        })
                    }
                })
                console.log(arr)
                setuser(arr);
            })
    }

    useEffect(() => {
        fetchPost();
    }, [])
    const homeBtn = () => {
        history.push("/userForm")
        window.location.reload()
    }
    const AddNewData = () => {
        history.push("/userForm")
        window.location.reload()
    }
    const editForm = (id) => {
        history.push(`/updateDetails/${id}`)
        window.location.reload()
    }

    const DeleteDoc = async (id) => {
        const docRef = doc(db, "user", id);
        debugger
        await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await deleteDoc(docRef)
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            "Deleted Sucessfully",
                            'success'
                        )
                        window.location.reload()
                    }).catch(() => {
                        Swal.fire(
                            'Problem!',
                            "Problem deleting doc",
                            'danger'
                        )
                    })

            }
        })
    }

    return (
        <>
            <div className='m-3'>
                <Card>

                    <CardTitle className="mb-0 p-3 border-bottom bg-light" >
                        <button className='mt-2 logoutBtn' onClick={homeBtn}>Home</button>
                        <Link to="/userForm">
                            <button style={{ float: "right" }} className='mt-2 logoutBtn'>Add</button>
                        </Link>
                        <h3 className='mt-3'>List of LOIs Submitted in last 90 days</h3>

                    </CardTitle>

                    <CardBody>
                        <ReactTable
                            columns={[
                                {
                                    width: 80,
                                    Header: "No.",
                                    accessor: "no",
                                },

                                {
                                    Header: "Listing Agent Name",
                                    accessor: "Name",
                                },
                                {
                                    Header: "Email",
                                    accessor: "Email",
                                },

                                {
                                    Header: "Listing address",
                                    accessor: "Address",
                                },
                                {
                                    Header: "Purchase Price",
                                    accessor: "Price",
                                },
                                {
                                    Header: "Due Deligence Period",
                                    accessor: "Due_Deligence_Period",
                                },
                                {
                                    Header: "Financial Contingency Period",
                                    accessor: "Finicial_Feasibility_Period",
                                },

                                {
                                    Header: "Closing",
                                    accessor: "Closing",
                                },
                                {
                                    Header: "Buyer side commission",
                                    accessor: "Commission",
                                },
                                {
                                    Header: "Ernest Money Deposit",
                                    accessor: "Deposite",
                                },
                                {
                                    Header: "Sender Name",
                                    accessor: "SenderName",
                                },
                                {
                                    Header: "Action",
                                    accessor: "actions",
                                    Cell: row => (

                                        <div className="d-flex justify-content-center">
                                            &nbsp;
                                            <AiFillEdit color="green" title="Edit " cursor="pointer" fontSize="1.50em" onClick={() => { editForm(user[row.index].id) }}
                                            />

                                            {<AiOutlineDelete
                                                //   onClick=  {() => { handleDelete(user[row.index].id) }}
                                                color="red" title="delete" cursor="pointer" fontSize="1.50em" onClick={() => { DeleteDoc(user[row.index].id) }}
                                            />}

                                        </div>
                                    ),

                                    sortable: false,
                                    filterable: false,
                                }


                            ]}
                            defaultPageSize={10}
                            showPaginationBottom={true}
                            className="-striped -highlight"
                            data={user}
                            filterable
                        />
                    </CardBody>
                </Card>
            </div>
        </>

    )
}

export default UserDataTable