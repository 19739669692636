import React, { useState } from 'react'
import propertyImg from "../assests/realtor-assistance-with-woman-house_23-2148657104.png"
import img from "../assests/jupiter-Texas.jpeg"

const PreviewDetails = (props) => {

  return (
    <>
      <div>
        <div className='mt-2' style={{ marginLeft: "20px" }}>
          <p>
            Dear  <strong style={{ color: "#0D1731" }}> {props.inputField.Name}</strong>,
          </p>
          <p>
            We're interested in purchasing your listing:
          </p>
          <p>
            <strong style={{ color: "#0D1731" }}>{props.inputField.Address}</strong>
          </p>
          <p>
            This property nicely compliments our growing portfolio. I've attached the LOI, OM and Bio for Jupiter Texas Real Estate Investment Group. We're also happy to prepare the PSA, if your seller wishes.
          </p>
          <p>
            The following are our offer terms:
          </p>
          <ul>
            <li>Purchase price: <strong style={{ color: "#0D1731" }}>${props.inputField.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong> </li>
            <li> Earnest money deposit:  <strong style={{ color: "#0D1731" }}> <strong style={{ color: "#0D1731" }}>${((props.inputField.Deposite / 100) * props.inputField.Price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>  ({props.inputField.Deposite}% of Purchase Price) </strong></li>
            <li> Due diligence period: <strong style={{ color: "#0D1731" }}>{props.inputField.Due_Deligence_Period} </strong>days from execution of purchase agreement </li>
            <li>  Financial feasibility period: <strong style={{ color: "#0D1731" }}>{props.inputField.Finicial_Feasibility_Period} </strong>days from execution of purchase agreement</li>
            <li>   Closing: <strong style={{ color: "#0D1731" }}>{props.inputField.Closing}</strong> days from execution of purchase agreement</li>
            <li>  Commission: Buyer is represented by Premier CRE Services, LLC, and will be paid by the seller <strong style={{ color: "#0D1731" }}>{props.inputField.Commission}% </strong>of selling price as commission at close</li>
          </ul>
          <p> To give you some background on us, Jupiter Texas Real Estate Investment Group was founded to make commercial real estate accessible to more investors.
            We currently have more than 750+ investors and $125M in assets under management and are growing.
            We have a diverse portfolio of properties around the United States and believe this property compliments our portfolio.
            Visit <a href='https://www.jupitertexas.com/' target='_blank'>www.JupiterTexas.com</a> to learn more about us!
            <br></br> Attached, please find:</p>
          <ul>
            <li>LOI</li>
            <li>OM</li>
            <li>Jupiter Texas - Meet our Team!</li>
            <li>Thank you, <strong style={{ color: "#0D1731" }}> {props.inputField.Name}</strong>. We look forward to working together on this property</li>
          </ul>
          <img src={img} width={150} />
       

        </div>
      </div>
    </>
  )
}

export default PreviewDetails