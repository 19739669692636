import React, { useState, useRef } from 'react'
import {
    Row,
    Col,

} from "reactstrap";
import PreviewDetails from './PreviewDetails';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firsebase.config';
import { useHistory } from "react-router"
import FormImg from './FormImg'
import { Link } from 'react-router-dom';
import moment from 'moment';
const EnterDetails = (props) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const a = new Date();
    const timestamp = a.getTime();
    const number = 123456789;

    const formattedNumber = number.toLocaleString("en-US");

    console.log(formattedNumber);
    const [inputField, setInputField] = useState({
        Name: "",
        Email: "",
        Address: "",
        Price: "",
        Deposite: "",
        Due_Deligence_Period: "",
        Finicial_Feasibility_Period: "",
        Closing: "",
        Commission: "",
        SenderName: "",
        Subject: "",
        date: timestamp,
        PDF: [],
        CalculatedAmount: ""
    })
    const InputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }
    const [images, setImages] = useState([]);

    const UploadDocument = async (e) => {
        try {
            debugger
            for (let i = 0; i < e.target.files.length; i++) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[i]);
                reader.onload = (readerEvent) => {
                    images.push(readerEvent.target?.result.replace("data:", "").replace(/^.+,/, ""));
                };
                inputField.PDF = images;
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                text: 'Their is an error Uploading This file!',
            })
        }
    };

    const form = useRef();
    const sendEmail = async (e) => {
        
        var a = ((inputField.Deposite / 100) * inputField.Price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        inputField.CalculatedAmount = a;
        var price = inputField.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        inputField.Price = price;
        e.preventDefault();

        try {


            await Swal.fire({
                title: "Are you sure?",
                html: "<b>Name : </b>" + inputField.Name + "<br/>" + "<b>Email :</b>" + inputField.Email + "<br/>" + "<b>Address :</b>" + inputField.Address + "<br/>" + "<b>Price :</b>" + inputField.Price + "<br/>" + "<b>Deposite :</b>" + inputField.Deposite + "<br/>" + "<b>Due Deligence Period :</b>" + inputField.Due_Deligence_Period + "<br/>" + "<b>Finicial Feasibility Period :</b>" + inputField.Finicial_Feasibility_Period + "<br/>" + "<b>Closing :</b>" + inputField.Closing + "<br/>" + "<b>Commission :</b>" + inputField.Commission + "<br/>" + "<b>Sender Name :</b>" + inputField.SenderName,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes !",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    debugger
                    setLoading(true);
                    emailjs.send('service_s6sua76', 'template_54q66ji', inputField, "li2AEH_4rMP-BmR-3")
                    await addDoc(collection(db, "user"), {
                        Name: inputField.Name,
                        Email: inputField.Email,
                        Address: inputField.Address,
                        Price: inputField.Price,
                        Deposite: inputField.Deposite,
                        Due_Deligence_Period: inputField.Due_Deligence_Period,
                        Finicial_Feasibility_Period: inputField.Finicial_Feasibility_Period,
                        Closing: inputField.Closing,
                        Commission: inputField.Closing,
                        SenderName: inputField.SenderName,
                        Subject: inputField.Subject,
                        date: timestamp,
                        PDF: [],
                        CalculatedAmount: inputField.CalculatedAmount
                    });
                    setLoading(true);
                    debugger
                    Swal.fire(
                        'Email Sended!',
                        "Email Sucessfully Send",
                        'success'
                    )
                    history.push("/userdata")
                    setLoading(false);
                }
            });
        } catch (e) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong!',
            })
            console.log(e)
            setLoading(false);

        }
    };

    const logoutBtn = () => {
        history.push("/login")
        localStorage.removeItem("real_state_admin")
    }


    return (
        <>
            <Row>
                <Col md="5">
                    <FormImg inputField={inputField} />
                </Col>
                <Col md="7">
                    <div className=''>
                        <Row>
                            <Col md="3">
                                <Link to="/userdata">
                                    <button className='mt-2 logoutBtn' >LOI List </button>
                                </Link>
                            </Col>
                            <Col md="6">
                                <h3 className='form-details mt-3'>Please Enter LOI Details Here</h3>
                            </Col>
                            <Col md="3">
                                <button className='mt-2 logoutBtn' style={{ marginLeft: "60px" }} onClick={logoutBtn}>Log Out</button>
                            </Col>
                        </Row>
                        <form onSubmit={sendEmail}>

                            <Row className="mt-3">
                                <Col md='6' className="mt-2">
                                    <label>Listing Agent Name : </label>
                                    <input onChange={InputHandler} value={inputField.Name} className='form-control' type="text" required placeholder='Name' name='Name' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Email:</label>
                                    <input onChange={InputHandler} value={inputField.Email} className='form-control' type="email" required placeholder='Email' name='Email' pattern="[^@\s]+@[^@\s]+\.[^@\s]+" />
                                </Col>

                                <Col md='6' className="mt-2">
                                    <label>Listing address : </label>
                                    <input onChange={InputHandler} value={inputField.Address} className='form-control' type="text" required placeholder='Address' name='Address' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Purchase Price : </label>
                                    <input onChange={InputHandler} value={inputField.Price} className='form-control' type="number" required placeholder="Price" name='Price' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Earnest Money Deposit :<span style={{ color: "#a19a9a" }}> (%)</span> </label>
                                    <input onChange={InputHandler} value={inputField.Deposite} className='form-control' type="number" required placeholder="Deposite" name='Deposite' min="1" max="10" pattern="[0-9]" />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Due Deligence Period : <span style={{ color: "#a19a9a" }}>(days)</span></label>
                                    <input onChange={InputHandler} value={inputField.Due_Deligence_Period} className='form-control' type="number" required placeholder="Due Deligence Period" name='Due_Deligence_Period' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Financial Contingency Period : <span style={{ color: "#a19a9a" }}>(days)</span></label>
                                    <input onChange={InputHandler} value={inputField.Finicial_Feasibility_Period} className='form-control' type="number" required placeholder="Finacial Feasibility Period" name='Finicial_Feasibility_Period' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Closing : <span style={{ color: "#a19a9a" }}>(days)</span></label>
                                    <input onChange={InputHandler} value={inputField.Closing} className='form-control' type="number" required placeholder="Closing" name='Closing' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Buyer side commission : <span style={{ color: "#a19a9a" }}>(%)</span></label>
                                    <input onChange={InputHandler} value={inputField.Commission} className='form-control' type="number" required placeholder="Commission" name='Commission' />
                                </Col>

                                <Col md='6' className="mt-2">
                                    <label>Sender Name:</label>
                                    <input onChange={InputHandler} value={inputField.SenderName} className='form-control' type="text" required placeholder="Sender Name" name='SenderName' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Subject: </label>
                                    <input onChange={InputHandler} value={inputField.Subject} className='form-control' type="text" required placeholder="Subject" name='Subject' />
                                </Col>
                                <Col md='6' className="mt-2">
                                    <label>Upoad File: <span style={{ color: "#a19a9a" }}>(.pdf)</span></label>
                                    <input onChange={UploadDocument} className='form-control' accept="application/pdf" type="file" multiple />
                                </Col>
                                <input onChange={InputHandler} value={inputField.CalculatedAmount} className='form-control' type="text" style={{ display: "none" }} placeholder="CalculatedAmount" name='CalculatedAmount' />

                            </Row>
                            <div className='form-details'>
                                {loading == true ? <button className='mt-5 sendBtn'
                                    type="submit"
                                    disabled
                                >Sending...</button> : <button className='mt-5 sendBtn'
                                    type="submit"
                                >Send</button>}
                            </div>
                        </form>


                    </div>
                </Col>
            </Row>


        </>
    )
}

export default EnterDetails