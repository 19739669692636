import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';

function LoginPage() {
    const [form, setForm] = useState(false)
    const [adminLogin, setAdminLogin] = useState({
        email: "",
        password: ""
    })

    let histosy = useHistory()
    const AdminloginBtn = () => {
        debugger
        if ((adminLogin.email == "admin" && adminLogin.password == "12345") || (adminLogin.email == "parthraichura@jupitertexas.com" && adminLogin.password == "12345")) {
            Swal.fire(
                'Login!',
                "Login Sucessfully as "+adminLogin.email,
                'success'
            )
            localStorage.setItem("real_state_admin","real_state_admin");
            histosy.push("/userdata")
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter the Correct information!',
            })
        }
    }
    const changeForm = () => {
        setForm(!form)
    }
    return (
        <div className="LoginPage">
            <div className="LoginPage__background"></div>
            <div className="LoginPage__form">
                <img src='https://static.wixstatic.com/media/03854e_f015b6df68c54bf2a1603eccd36a9e60~mv2.png/v1/crop/x_0,y_4,w_645,h_891/fill/w_90,h_125,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Jen---image3-1920w_webp.png'/>
                    <h3 className='mt-2'>Jupiter Texas Solutions</h3>
                    <input autoComplete="off" className='form-control mb-4 mt-4'
                        value={adminLogin.email}
                        name="email"
                        onChange={(e) => { setAdminLogin({ ...adminLogin, email: e.target.value }) }}
                        type="text" placeholder='email' />

                    <input autoComplete="off" className='form-control' type="password"
                        value={adminLogin.password}
                        name="password"
                        onChange={(e) => { setAdminLogin({ ...adminLogin, password: e.target.value }) }}
                        placeholder='Password' />
                    <button className='mt-4 loginBtn' onClick={AdminloginBtn} type="submit">Submit</button>


                </div>
         
        </div>
    );
}

export default LoginPage;